<template>
    <component
        :is="isInfo(item) || isButton(item) ? 'ul' : 'div'"
        :class="className(item)"
    >
        <template v-if="/linkTitle|linkMore|linkDescription/.test(item.type)">
            <a-link
                :to="item.to"
                :text="item.text"
                :glyph="item.type === 'linkMore' ? 'long-arrow' : ''"
                :event="eventGA(item)"
            />
            <a-label v-if="item.label" v-bind="getLabelProps(item.label)">
                <span>{{ item.label.text }}</span>
            </a-label>
            <a-button
                v-if="item.button"
                v-bind="item.button"
                :type="item.button.type || 'main'"
            />
            <template v-if="isMobile && version.startsWith('46942')">
                <a-dangerous-html
                    v-if="item.desc || item.type === 'desc'"
                    :key="getDescription(item)"
                    :content="getDescription(item)"
                    :class="`s-menu-tab__${item.desc ? 'description' : 'text'}`"
                />
            </template>
        </template>
        <template v-if="!version.startsWith('46942') || (version.startsWith('46942') && !isMobile)">
            <a-dangerous-html
                v-if="item.desc || item.type === 'desc'"
                :key="getDescription(item)"
                :content="getDescription(item)"
                :class="`s-menu-tab__${item.desc ? 'description' : 'text'}`"
            />
        </template>
        <template v-if="item.type === 'linkStrong'">
            {{ item.text }}
        </template>
        <template v-if="item.type === 'listColumns'">
            <div
                v-for="(column, i) in item.columns"
                :key="`column-list-item-${i}`"
                class="list-column"
            >
                <template v-for="(colItem, j) in column">
                    <div
                        v-if="colItem.type === 'category'"
                        :key="`column-list-item-${i}-${j}`"
                        class="list-column-category"
                    >
                        {{ colItem.text }}
                    </div>
                    <a-link
                        v-if="colItem.type === 'link'"
                        :key="`column-list-item-${i}${j}`"
                        class="list-column-link"
                        :class="{
                            'has-star': colItem.glyph === 'star',
                            'text-thin': colItem.thinText
                        }"
                        :to="colItem.to"
                        :text="colItem.text"
                        :glyph="colItem.glyph || ''"
                        glyph-position="left"
                        :event="eventGA(colItem)"
                    />
                </template>
            </div>
        </template>
        <template v-if="isInfo(item)">
            <li
                v-for="(listItem, listItemIndex) in item.items"
                :key="`${item.type}-item-${listItemIndex}`"
                :class="`s-menu-tab__${item.type.toLowerCase()}-item`"
            >
                <a-link
                    v-if="listItem.modal && isDesktop"
                    v-modal="listItem.modal"
                    text="Interactive demo"
                    :class="getItemClass(item, listItem)"
                    :glyph="listItem.glyph || (item.type === 'cta' ? 'long-arrow' : '')"
                    :glyph-position="item.type === 'cta' ? 'right' : 'left'"
                    :event="eventGA(listItem)"
                    :modal-events="listItem.modalEvents"
                />
                <a-link
                    v-else-if="!listItem.modal || !isDesktop"
                    :to="listItem.to"
                    :text="listItem.text"
                    :class="getItemClass(item, listItem)"
                    :glyph="listItem.glyph || (item.type === 'cta' ? 'long-arrow' : '')"
                    :glyph-position="item.type === 'cta' ? 'right' : 'left'"
                    :event="eventGA(listItem)"
                />
                <a-dangerous-html
                    v-if="listItem.desc"
                    class="s-menu-tab__description"
                    :content="listItem.desc"
                />
                <a-label v-if="listItem.label" v-bind="getLabelProps(listItem.label)">
                    <span>{{ listItem.label.text }}</span>
                </a-label>
            </li>
        </template>
        <template v-if="isButton(item)">
            <li
                v-for="(button, buttonIndex) in item.items"
                :key="`${button.type}-item-${buttonIndex}`"
                :class="`s-menu-tab__${item.type.toLowerCase()}-item`"
            >
                <a-button
                    v-bind="button"
                    :size="button.size || 's'"
                    :type="button.type || 'main'"
                    :glyph="button.glyph || 'i-long-arrow-right--16'"
                />
            </li>
        </template>
    </component>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SMenuTabSection',

    components: {
        AButton,
        ALabel,
        ADangerousHtml,
        ALink,
    },

    directives: { Modal },

    mixins: [breakpoint],

    props: {
        id: {
            type: String,
            default: 'Item',
        },
        version: {
            type: String,
            default: '',
        },
        activeTabIndex: {
            type: Number,
            default: 0,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },

    methods: {
        className(item) {
            if (this.isInfo(item) || this.isButton(item)) return this.classNameList(item);
            return {
                'has-margin': item.hasMargin,
                'list-columns': item.type === 'listColumns',
                's-menu-tab__link-strong': item.type === 'linkStrong',
                [`s-menu-tab__${item.type?.toLowerCase()}`]: /linkTitle|linkMore|linkDescription/.test(item.type),
            };
        },

        classNameList(item) {
            const classes = [`s-menu-tab__${item.type.toLowerCase()}`];
            if (item.class) {
                classes.push(`s-menu-tab__${item.type.toLowerCase()}_${item.class}`);
            }
            if (item.bottomDivider) {
                classes.push('s-menu-tab__bottom-divider');
            }
            return classes;
        },

        isButton(item) {
            return ['button'].includes(item.type);
        },

        isInfo(item) {
            return ['cta', 'list', 'glyphList'].includes(item.type);
        },

        eventGA(item) {
            const event = {
                context: 'click',
                content: item.text,
                action: `Menu ${this.id.charAt(0).toUpperCase() + this.id.slice(1)}`,
            };
            if (this.tabs?.eventLocation?.length) {
                event.location = this.tabs.eventLocation?.[this.activeTabIndex] || '';
            }
            return { ...event, ...item.event };
        },

        getDescription(item) {
            return item.desc || item.text;
        },

        getLabelProps(item) {
            return {
                size: item.size || 's',
                variant: item.variant || 'info',
                theme: item.theme || 'light',
                layout: item.layout || 'solid',
            };
        },

        getItemClass(item, listItem) {
            return [
                `s-menu-tab__${item.type.toLowerCase()}-item-link`,
                `s-menu-tab__${item.type.toLowerCase()}-item-link--${listItem.type}`,
                {
                    'has-star': listItem.glyph === 'star',
                    'text-thin': listItem.thinText,
                    'make-accent': listItem.accent,
                },
            ];
        },
    },

};
</script>

<style lang="postcss" scoped>
.list-columns {
    display: flex;
    flex-direction: column;
    padding-inline-start: 16px;

    @media (--viewport-desktop) {
        gap: 20px;
        flex-direction: row;
    }
}

 .list-column {
    display: flex;
    flex-direction: column;

    @media (--viewport-tablet) {
        max-width: 228px;
    }

    @media (--viewport-desktop) {
        flex-basis: 100%;
    }

    @media (--viewport-desktop-wide) {
        max-width: 276px;
    }

    &-category {
        @mixin paragraph-accent;
        color: var(--av-nav-primary);
        margin: 16px 0;

        @media (--viewport-desktop) {
            margin: 24px 0 16px;
        }
    }

    &-link {
        margin-bottom: 8px;
        font-weight: 700;

        &.text-thin {
            font-weight: 400;
        }

        &:deep(.a-link__content.glyphLeft) {
            padding-inline-start: 20px;
            margin-inline-start: -20px;
        }
    }
}
</style>
