<template>
    <div class="blog-news-search">
        <div class="filters">
            <div class="filter-label">
                {{ filterNewsBy }}
            </div>
            <el-select
                v-model="productFilter"
                class="filter-select"
                :label="productLabel"
                :hide-on-resize="isDesktop"
                :placeholder="selectAll"
                filterable
                multiple
                popper-class="blog-dropdown filter-select-dropdown"
                @change="onFiltersChanged"
            >
                <el-option :label="selectAll" :value="ALL_OPTION_KEY" />
                <el-option
                    v-for="item in products"
                    :key="item.id"
                    :label="`Acronis ${item.name}`"
                    :value="item.id"
                    @click.native="sendGAEvent({ label: 'product', content: `Acronis ${item.name}` })"
                />
            </el-select>
            <el-select
                v-model="yearFilter"
                class="filter-select"
                :label="yearLabel"
                :hide-on-resize="isDesktop"
                popper-class="blog-dropdown filter-select-dropdown"
                :class="{ 'form-error': yearError }"
                @change="onFiltersChanged"
            >
                <el-option :value="selectAll" />
                <el-option
                    v-for="item in years"
                    :key="item"
                    :label="item"
                    :value="item"
                    @click.native="sendGAEvent({ label: 'year', content: item + '' })"
                />
            </el-select>
            <el-select
                v-model="monthFilter"
                class="filter-select"
                :label="monthLabel"
                :hide-on-resize="isDesktop"
                popper-class="blog-dropdown filter-select-dropdown"
                @change="onFiltersChanged"
            >
                <el-option :value="selectAll" />
                <el-option
                    v-for="(item, i) in months"
                    :key="item"
                    :label="item"
                    :value="i + 1"
                    @click.native="sendGAEvent({ label: 'month', content: item })"
                />
            </el-select>
            <div class="tags-list">
                <div class="tags-search-wrapper">
                    <el-input
                        v-model="tagSearchString"
                        name="search-by-tag"
                        :label="searchByTagLabel"
                        @blur="($e) => sendGAEvent({ label: 'search by tag', content: $e.target.value })"
                    />
                    <a-glyph name="search" />
                </div>
                <div class="selected-tags">
                    <div
                        v-for="tag in tagsList"
                        :id="tag.id"
                        :key="tag.id"
                        class="tag"
                        @click="toggleTag(tag)"
                    >
                        <span>{{ tag.title }}</span>
                        <a-glyph v-show="tag.selected" name="close" size="xs" />
                    </div>
                </div>
                <div
                    v-if="tags.length > VISIBLE_TAGS_LIMIT && !tagSearchString"
                    class="more-tags-block"
                    :class="{ opened: moreTagsVisible }"
                >
                    <a-link
                        :text="moreTagsVisible ? collapseLabel : exploreMoreTagsLabel"
                        glyph="arrow-narrow-up"
                        glyph-size="xs"
                        :has-no-ref="true"
                        @click="toggleMoreTags"
                    />
                </div>
            </div>
            <a-link
                class="global-search-link"
                v-bind="globalSearchLink"
                glyph="arrow"
                target="_blank"
                glyph-size="xs"
                @click="sendGAEvent({ label: 'search by keywords', content: '' })"
            />
        </div>
        <div class="news">
            <div class="title">
                {{ title }}
            </div>
            <div v-if="$slots.searchResults" class="results">
                <slot name="searchResults" />
            </div>
            <div v-else class="no-results">
                <p>{{ noNewsFound }}</p>
                <p v-if="yearFilter !== selectAll || monthFilter !== selectAll">
                    {{ tryResetDateFiltersLabel }}
                </p>
                <p v-else>
                    {{ tryResetFiltersLabel }}
                </p>
            </div>
            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-size="48"
            />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import commonUtils from '@utils/common';

const VISIBLE_TAGS_LIMIT = 10;
const ALL_OPTION_KEY = 0;

export default {
    name: 'BlogNewsSearch',
    components: {
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        AGlyph,
        ALink,
    },
    directives: {
        Loading,
    },
    mixins: [breakpoint],
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },

        noNewsFound: {
            type: String,
            required: true,
        },

        filterNewsBy: {
            type: String,
            required: true,
        },

        followUsOn: {
            type: String,
            required: true,
        },

        productLabel: {
            type: String,
            required: true,
        },

        yearLabel: {
            type: String,
            required: true,
        },

        monthLabel: {
            type: String,
            required: true,
        },

        selectAll: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        years: {
            type: Array,
            required: true,
        },

        products: {
            type: Array,
            required: true,
        },

        tags: {
            type: Array,
            default: () => [],
        },

        searchByTagLabel: {
            type: String,
            required: true,
        },

        collapseLabel: {
            type: String,
            required: true,
        },

        tryResetFiltersLabel: {
            type: String,
            required: true,
        },

        tryResetDateFiltersLabel: {
            type: String,
            required: true,
        },

        exploreMoreTagsLabel: {
            type: String,
            required: true,
        },

        globalSearchLink: {
            type: Object,
            required: true,
        },
    },
    emits: ['updateFilterParams'],
    data() {
        return {
            productFilter: [ALL_OPTION_KEY],
            yearFilter: this.selectAll,
            monthFilter: this.selectAll,
            yearError: false,
            months: commonUtils.getArrayOfMonths(),
            tagSearchString: '',
            moreTagsVisible: false,
            selectedTagsIds: [],
            VISIBLE_TAGS_LIMIT,
            ALL_OPTION_KEY,
        };
    },
    computed: {
        tagsList() {
            const selectedTagsIds = new Set(this.selectedTagsIds);
            const res = this.tags
                .map((tag) => ({
                    id: tag.id,
                    title: tag.title,
                    selected: selectedTagsIds.has(tag.id),
                }))
                .sort((a, b) => b.selected - a.selected);

            if (this.tagSearchString) {
                const tagSearchString = this.tagSearchString.toLowerCase();
                return res
                    .filter((tag) => tag.title.toLowerCase().includes(tagSearchString))
                    .slice(0, VISIBLE_TAGS_LIMIT);
            }

            if (this.tags.length > VISIBLE_TAGS_LIMIT && !this.moreTagsVisible) {
                return res.slice(0, VISIBLE_TAGS_LIMIT);
            }

            return res;
        },
    },
    methods: {
        onFiltersChanged() {
            // select "all" option only if it's last or unselect "all" otherwise
            if (!this.productFilter?.length) {
                this.productFilter = [ALL_OPTION_KEY];
            } else if (this.productFilter.length > 1 && this.productFilter.includes(ALL_OPTION_KEY)) {
                if (this.productFilter[this.productFilter.length - 1] === ALL_OPTION_KEY) {
                    this.productFilter = [ALL_OPTION_KEY];
                } else if (this.productFilter[this.productFilter.length - 1] !== ALL_OPTION_KEY) {
                    this.productFilter = this.productFilter.filter((value) => value !== ALL_OPTION_KEY);
                }
            }
            this.applyFilters();
        },

        applyFilters() {
            this.yearError = false;

            const year = this.yearFilter === this.selectAll ? null : this.yearFilter;
            const month = this.monthFilter === this.selectAll ? null : this.monthFilter;
            const products = this.productFilter.filter((value) => value !== ALL_OPTION_KEY);
            const tags = this.selectedTagsIds;

            if (month && !year) {
                this.yearError = true;
                return;
            }

            this.$emit('updateFilterParams', { year, month, products, tags });
        },

        toggleTag(tag) {
            if (tag.selected) {
                this.selectedTagsIds = this.selectedTagsIds.filter((id) => id !== tag.id);
            } else {
                this.selectedTagsIds.push(tag.id);
                this.sendGAEvent({ label: 'tag', content: tag.title });
            }
            this.applyFilters();
        },

        toggleMoreTags() {
            this.moreTagsVisible = !this.moreTagsVisible;
        },

        sendGAEvent(data) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'filter',
                eventContext: 'click',
                eventLabel: data.label,
                eventContent: data.content,
                eventLocation: '',
                eventButton: '',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-news-search {
    @media (--viewport-tablet) {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.news-title {
    @mixin colls 12;
    @mixin display-accent;
    text-align: center;
    display: none;
    margin-bottom: 24px;
    @media (--viewport-desktop-large) {
        @mixin hero-accent;
    }
}

.filters {
    margin-bottom: 48px;

    @media (--viewport-tablet) {
        margin-bottom: 0;
        padding-inline-end: 16px;
    }
}

.filter-label {
    @mixin lead;

    margin-bottom: 16px;
    text-align: center;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        text-align: start;
    }
}

.filter-select {
    margin-bottom: 16px;
    background: var(--av-inversed-primary);
    color: var(--av-fixed-secondary);
    border-radius: 4px;
    height: 46px;
}

.filter-button {
    margin-top: 8px;
    height: 48px;
    width: 100%;
    border-radius: 4px;
    background: var(--av-brand-primary);
}

.news {
    position: relative;
    @media (--viewport-tablet) {
        grid-column-start: 2;
        grid-column-end: 5;
    }
}

.loading {
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.title {
    @mixin display;

    margin-bottom: 64px;
    text-align: center;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        @mixin hero;

        margin-bottom: 24px;
        text-align: start;
    }
}

.tags-list {
    width: 100%;
    margin-bottom: 24px;

    &:deep(.el-input__editor) {
        padding-inline-end: 20px;
    }
}

.tags-search-wrapper {
    position: relative;

    &:deep(.a-glyph) {
        position: absolute;
        top: 16px;
        inset-inline-end: 12px;
        pointer-events: none;
        fill: #000;
    }
}

.selected-tags {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &:deep(.resource-search-tag) {
        margin-bottom: 4px;
    }
}

.more-tags-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;

    &:deep(.a-glyph) {
        top: 7px;
        width: 12px;
        transform: rotate(180deg);
    }

    &:deep(.a-link) {
        width: 100%;
    }

    &:deep(.a-link__content) {
        background: transparent !important;
    }

    &.opened {
        &:deep(.a-glyph) {
            transform: rotate(0);
        }
    }
}

.tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 0 12px 1px;
    background: var(--av-brand-lightest);
    border: 1px solid var(--av-brand-light);
    border-radius: 14px;
    @mixin body;
    color: var(--av-fixed-primary);
    cursor: pointer;

    &:deep(.a-glyph) {
        width: 10px;
        height: 10px;
        margin-top: 2px;
        flex-shrink: 0;
        cursor: pointer;
    }
}

.global-search-link {
    &:deep(.a-glyph) {
        margin-bottom: 5px;
    }
}

</style>

<style lang="postcss">
.blog-news-search {
    .filter-select {
        &.form-error {
            .el-input__container {
                border-color: var(--av-fixed-danger);
            }
        }

        .el-input {
            height: 100%;

            &__container {
                height: 100%;
                padding: 0;
                padding-inline-end: 9px;
                padding-inline-start: 7px;
            }

            &__icon {
                color: var(--av-brand-primary) !important;
            }

            &__placeholder {
                @mixin body;

                color: var(--av-fixed-light);
            }

            &__editor--absolute-position {
                @mixin body;
                color: var(--av-nav-primary);
            }

            &__label.is-active {
                color: var(--av-fixed-light);
                padding-top: 3px;
            }
        }

        .el-input.is-active:not(.el-input--small).el-input--has-label .el-input__wrapper {
            padding-top: 19px;
        }
    }
}
</style>
