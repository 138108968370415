/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

/* styles for centered version of menu tabs (on minisite) */

@media (min-width: 1024px) {
                .s-menu-tab.s-menu-tab_centered .s-menu-tab__glyphlist-item-link,
                .s-menu-tab.s-menu-tab_centered .s-menu-tab__linkmore {
                    vertical-align: top;
                }

                .s-menu-tab.s-menu-tab_centered .s-menu-tab__body {
                    display: flex;
                    padding: 8px 0;
                    padding-inline-end: 136px;
                    padding-inline-start: 64px;
                    grid-column-gap: 48px;
                }

                .s-menu-tab.s-menu-tab_centered .s-menu-tab__list,
                .s-menu-tab.s-menu-tab_centered .s-menu-tab__list ~ .s-menu-tab__linkmore {
                    margin-top: 0;
                }
                    .s-menu-tab.s-menu-tab_centered .s-menu-tab__linkmore + .s-menu-tab__list,
                    .s-menu-tab.s-menu-tab_centered .s-menu-tab__linkmore + .s-menu-tab__list ~ .s-menu-tab__linkmore,
                    .s-menu-tab.s-menu-tab_centered .s-menu-tab__link-strong + .s-menu-tab__list,
                    .s-menu-tab.s-menu-tab_centered .s-menu-tab__link-strong + .s-menu-tab__list ~ .s-menu-tab__linkmore {
                        margin-top: 24px;
                    }
                    .s-menu-tab.s-menu-tab_centered .s-menu-tab__section_submenu {
                        padding-inline-start: 24px;
                    }

                        .s-menu-tab.s-menu-tab_centered .s-menu-tab__section_submenu::before {
                            inset-inline-start: 0;
                        }
                        .s-menu-tab.s-menu-tab_centered .s-menu-tab__section_divider_short {
                            min-width: 180px;
                        }
        }

.s-menu-tab__body {
        padding: 0 16px 8px;
    }

@media (min-width: 768px) {

.s-menu-tab__body {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 32px;
            padding: 0 48px 8px;
            padding-inline-start: 32px
    }
        }

@media (min-width: 1024px) {

.s-menu-tab__body {
            grid-column-gap: 32px;
            padding-inline-end: 0
    }
        }

@media (min-width: 1280px) {

.s-menu-tab__body {
            grid-column-gap: 72px;
            padding-inline-start: 64px
    }
        }

@media (min-width: 1440px) {

.s-menu-tab__body {
            grid-column-gap: 80px;
            padding-inline-start: 0
    }
        }

.s-menu-tab__section {
        padding: 32px 0;
    }

@media (min-width: 768px) {

.s-menu-tab__section_full-width {
                display: flex;
                justify-content: space-between;
                align-items: center
        }
            }

.s-menu-tab__section_divider_wide,
            .s-menu-tab__section_divider_short {
                position: relative;
            }

.s-menu-tab__section_divider_wide::before, .s-menu-tab__section_divider_short::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    inset-inline-start: 0;
                    height: 1px;
                    background-color: var(--av-brand-accent);
                }

@media (min-width: 768px) {

.s-menu-tab__section_divider_wide::before, .s-menu-tab__section_divider_short::before {
                        display: none
                }
                    }

.s-menu-tab__section_divider_short::before {
                    width: 54px;
                }

.s-menu-tab__section_divider_wide::before {
                    width: 100%;
                }

@media (min-width: 768px) {

.s-menu-tab__section_submenu {
                border-top: 1px solid var(--av-brand-accent)
        }
            }

@media (min-width: 1024px) {

.s-menu-tab__section_submenu {
                position: relative;
                border-top: none
        }

                .s-menu-tab__section_submenu::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    inset-inline-start: -24px;
                    width: 1px;
                    height: calc(100% + 8px);
                    background-color: var(--av-brand-accent);
                }
            }

.s-menu-tab__section_full-width {
        padding-top: 32px;
        padding-bottom: 40px;
    }

@media (min-width: 768px) {

.s-menu-tab__section_full-width {
            padding-top: 24px;
            padding-bottom: 36px
    }
        }

@media (min-width: 1024px) {

.s-menu-tab__section_full-width {
            padding-bottom: 32px
    }
        }

@media (min-width: 1280px) {

.s-menu-tab__section_full-width {
            padding-bottom: 16px
    }
        }

@media (min-width: 1440px) {

.s-menu-tab__section_full-width {
            padding-bottom: 32px
    }
        }

.s-menu-tab__linktitle .a-link__content {
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
        }

@media (min-width: 768px) {

.s-menu-tab__linktitle .a-link__content {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700
        }
            }

.s-menu-tab__linktitle .a-link__content {
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
}

@media (min-width: 768px) {

.s-menu-tab__linktitle .a-link__content {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700
        }
            }

.s-menu-tab__description {
        display: block;
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
        color: var(--av-fixed-secondary);
    }

.s-menu-tab__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px 0;
    }

.s-menu-tab__text {
        font-size: 16px;
        line-height: 24px;
        color: var(--av-fixed-secondary);
    }

.s-menu-tab__text a:hover {
            color: var(--av-link-primary-hover);
        }

.s-menu-tab__link-strong {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        color: var(--av-nav-primary);
    }

.s-menu-tab__link-strong .a-link__content {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: var(--av-nav-primary);
        }

.s-menu-tab__link-strong:hover .a-link__content, .s-menu-tab__link-strong:focus .a-link__content {
                background: none;
                color: var(--av-nav-primary);
            }

.s-menu-tab__link-strong.nuxt-link-exact-active .a-link__content {
                color: var(--av-nav-primary);
                pointer-events: auto;
            }

.s-menu-tab__bottom-divider {
        position: relative;
        padding-bottom: 24px;
    }

.s-menu-tab__bottom-divider::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            width: 130px;
            height: 1px;
            background: var(--av-brand-accent);
        }

.s-menu-tab__list ~ .s-menu-tab__linkmore {
                margin: 32px 0 0;
            }

.s-menu-tab__list,
    .s-menu-tab__cta {
        margin: 24px 0 0;
    }

.s-menu-tab__list_nomargin, .s-menu-tab__cta_nomargin {
            margin-top: 0;
        }

.s-menu-tab__list_inline, .s-menu-tab__cta_inline {
            gap: 16px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }

.s-menu-tab__list_inline .s-menu-tab__cta-item:not(:first-child), .s-menu-tab__cta_inline .s-menu-tab__cta-item:not(:first-child) {
                    margin: 0!important;
                }

.s-menu-tab__description + .s-menu-tab__list {
        margin-top: 24px !important;
    }

.s-menu-tab__cta-item {
        line-height: 0;
    }

.s-menu-tab__cta-item:not(:first-child) {
            margin: 16px 0 0;
        }

.s-menu-tab__list-item-link,
    .s-menu-tab__cta-item-link {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
    }

.s-menu-tab__list-item-link:hover, .s-menu-tab__cta-item-link:hover {
            color: var(--av-link-primary-hover);
        }

@media (min-width: 1024px) {

.s-menu-tab__list-item-link,
    .s-menu-tab__cta-item-link {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700
    }
        }

.s-menu-tab__list-item-link {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
    }

.s-menu-tab__list-item-link.has-star .a-link__content.glyphLeft {
                    margin-inline-start: -24px;
                }

.s-menu-tab__list-item-link.has-star .a-link__content .a-glyph {
                    top: 1px;
                }

.s-menu-tab__list-item-link.text-thin {
            font-weight: 400;
        }

.s-menu-tab__list-item-link--category .a-link__content {
                color: var(--av-nav-primary);
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
            }

.s-menu-tab__cta-item-link {
        padding: 8px;
    }

.s-menu-tab__cta-item-link.make-accent {
            background: var(--el-secondary-hover);
            border-radius: 4px;
        }

.s-menu-tab__cta-item-link .a-link__content:not(:hover) {
            color: var(--av-nav-primary);
        }

.s-menu-tab__cta-item-link .a-link__content:not(:hover) .a-glyph {
                color: var(--av-brand-primary);
            }

@media (min-width: 768px) {

.s-menu-tab__glyphlist {
            display: flex;
            align-items: center
    }
        }

.s-menu-tab__glyphlist-item:not(:last-of-type) {
            margin-bottom: 24px;
        }

@media (min-width: 768px) {

.s-menu-tab__glyphlist-item:not(:last-of-type) {
                margin-bottom: 0;
                margin-inline-end: 56px
        }
            }

.s-menu-tab__glyphlist-item .a-link {
            margin: 0;
        }

.s-menu-tab__glyphlist-item-link .a-glyph {
            margin: 0 0 2px;
            margin-inline-end: 8px;
        }

.s-menu-tab__linkmore .a-link__content, .s-menu-tab__glyphlist-item-link .a-link__content {
            color: var(--av-nav-primary);
        }

.s-menu-tab__linkmore .a-link__content svg, .s-menu-tab__glyphlist-item-link .a-link__content svg {
                color: var(--av-brand-primary);
            }

.s-menu-tab__linkmore:hover .a-link__content, .s-menu-tab__linkmore:focus .a-link__content, .s-menu-tab__glyphlist-item-link:hover .a-link__content, .s-menu-tab__glyphlist-item-link:focus .a-link__content {
                background: none;
                color: var(--av-link-primary-hover);
            }

.s-menu-tab__linkmore:hover .a-link__content svg, .s-menu-tab__linkmore:focus .a-link__content svg, .s-menu-tab__glyphlist-item-link:hover .a-link__content svg, .s-menu-tab__glyphlist-item-link:focus .a-link__content svg {
                    fill: var(--av-brand-secondary);
                }

.s-menu-tab__linkmore {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
    }

.s-menu-tab__linkmore .a-link__glyph {
            margin-inline-start: 8px;
        }

.s-menu-tab__glyphlist-item-link {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
    }

.s-menu-tab__items-4 .s-menu-tab__body {
            display: grid;
            grid-column-gap: 32px;
        }

.s-menu-tab__items-4 .s-menu-tab__body .s-menu-tab__section {
                margin: 0;
            }

@media (min-width: 1024px) {

.s-menu-tab__items-4 .s-menu-tab__body {
                grid-template-columns: repeat(4, 1fr)
        }
            }

@media (min-width: 1280px) {

.s-menu-tab__items-4 .s-menu-tab__body {
                grid-column-gap: 24px
        }
            }

@media (min-width: 1024px) {

.s-menu-tab__items-4 .s-menu-tab__section {
                max-width: 164px
        }
            }

@media (min-width: 1280px) {

.s-menu-tab__items-4 .s-menu-tab__section {
                max-width: 264px
        }
            }

@media (min-width: 1440px) {

.s-menu-tab__items-4 .s-menu-tab__section {
                max-width: 288px
        }
            }

@media (min-width: 768px) {

.s-menu-tab__items-4 .s-menu-tab__section:last-child {
                grid-column: span 3
        }
            }

@media (min-width: 1024px) {

.s-menu-tab__items-4 .s-menu-tab__section:last-child {
                grid-column: auto
        }
            }

@media (min-width: 1280px) {

.s-menu-tab__items-4 .s-menu-tab__section:last-child {
                max-width: 204px
        }
            }

@media (min-width: 1440px) {

.s-menu-tab__items-4 .s-menu-tab__section:last-child {
                max-width: 260px
        }
            }

.s-menu-tab .s-menu-tab__section-row {
        gap: 32px;
        display: flex;
        flex-direction: column;
    }

@media (min-width: 1024px) {

.s-menu-tab .s-menu-tab__section-row {
            flex-direction: row;
            width: calc(200% + 32px)
    }
        }

.s-menu-tab .s-menu-tab__section-row:not(:first-child) {
            padding-top: 24px;
        }

.s-menu-tab .s-menu-tab__section-row .s-menu-tab__section-column {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

.s-menu-tab .s-menu-tab__section-row .s-menu-tab__section-column .s-menu-tab__cta {
                padding-top: 16px;
                margin-top: auto!important;
            }

.s-menu-tab .s-menu-tab__section-row .s-menu-tab__section-column .s-menu-tab__linktitle {
                margin: 0!important;
            }

@media (min-width: 1024px) {

.s-menu-tab .s-menu-tab__section-row .s-menu-tab__section-column .s-menu-tab__linktitle {
                    padding-top: 40px
            }
                }

@media (min-width: 1024px) {

.s-menu-tab .s-menu-tab__section-row .s-menu-tab__section-column .s-menu-tab__link-strong ~ .s-menu-tab__linktitle {
                        padding-top: 8px
                }
                    }

#ot-sdk-btn-floating.ot-floating-button button {
            text-align: center;
        }

#ot-sdk-btn-floating.ot-floating-button button svg {
                vertical-align: middle;
            }

/* old solution tab support */

@media (min-width: 1024px) {

.s-main-header .s-menu-tab__body_type_solutions {
            grid-template-columns: repeat(2, 1fr)
    }
        }

@media (min-width: 1024px) {

.s-main-header .s-menu-tab__body_type_solutions {
            display: flex;
            gap: 48px;
            justify-content: space-between
    }
        }
